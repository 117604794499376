<template>
    <b-card-header class="bg-light d-flex">
        <b-nav card-header pills class="pl-2">
            <b-nav-item :to="`/attributes/${$route.params.id}`" exact exact-active-class="active">Attribute</b-nav-item>
            <b-nav-item :to="`/attributes/${$route.params.id}/claims`" exact exact-active-class="active">Search</b-nav-item>
        </b-nav>
        <b-form-input class="ml-auto" v-model="search" placeholder="Search" style="max-width: 20rem;"></b-form-input>
        <b-button class="ml-2" variant="primary" v-on:click="$emit('load', 'claims', search)" :disabled="!search || search.length < 2">Search</b-button>
    </b-card-header>
    <b-card-header class="text-muted bg-light">
        <small>Untargeted search is not allowed. Your search must contain at least 2 characters.</small>
    </b-card-header>
    <b-card-body v-if="claims">
        <b-table id="table" outlined hover :items="claims.items" :fields="fields" primary-key="id" sort-by="time" sort-desc :filter="search" per-page="10" :current-page="page" v-on:filtered="filteredItems => rows = filteredItems.length" v-on:row-clicked="item => $router.push(`/claims/${item.id}`)" class="mb-0" show-empty empty-text="Please perform a search." empty-filtered-text="No attributes match your search. If more attributes are available you can load them by clicking 'Load More' below. Please note that you must click 'Search' above in order to first perform the search on the server as opposed to searching on the client (default).">
            <template #cell(account)="data">
                <b-link :to="`/accounts/${data.value}`">{{ data.value }}</b-link>
            </template>
            <template #cell(status)="data">
                <b-button :variant="variant(data.value)" size="sm" disabled>{{ statuses[data.value] }}</b-button>
            </template>
        </b-table>
    </b-card-body>
    <b-card-body class="d-flex">
        <b-button variant="outline-primary" v-on:click="$emit('load', 'claims', search)">Refresh</b-button>
        <b-button variant="outline-primary" class="ml-2" v-on:click="page--" :disabled="page < 2">Previous</b-button>
        <b-button v-if="claims" variant="outline-primary" class="ml-2" v-on:click="page++" :disabled="!Math.max(( rows ?? claims.items.length) - page * 10, 0)">Next</b-button>
        <b-button v-if="claims?.nextToken" variant="outline-primary" v-on:click="$emit('next', 'claims', search)" class="ml-2" :disabled="loading_more">Load More</b-button>
    </b-card-body>
    <b-card-footer v-if="claims?.refreshed_at" class="text-muted bg-light">
        <small>Last refreshed at {{ claims.refreshed_at.toLocaleString() }}</small>
    </b-card-footer>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
 const FIELDS = [
    {
        key: 'label',
        label: 'Label',
        sortable: true
    },
    {
        key: 'value',
        label: 'Value',
        sortable: true
    },
    {
        key: 'account',
        label: 'Account',
        sortable: true
    },
    {
        key: 'created_at',
        label: 'Created At',
        sortable: true,
        formatter: value => new Date(value).toLocaleString()
    },
    {
        key: 'status',
        label: 'Status',
        sortable: true
    }
];
const STATUSES = {
    'PENDING': 'Pending',
    'ENABLED': 'Enabled',
    'DISABLED': 'Disabled',
    'LOCKED': 'Locked'
};

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'AttributeClaims',

    /**
     * EVENTS
     */
     emits: [ 'load', 'next' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function,
        variant: Function,
        loading_more: String,
        claims: Array
    },

    /**
     * DATA
     */
    data() {
        return {
            // FIELDS
            fields: FIELDS,
            // STATUSES
            statuses: STATUSES,
            // PAGE,
            page: 1,
            // ROWS
            rows: undefined,
            // SEARCH
            search: undefined
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 }
}
</script>