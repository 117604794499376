<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/extensions.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Extension</h3>
            </b-card-header>
            <b-card-body v-if="extension">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="ID" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="extension.id" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Label" label-align-sm="right" label-cols-sm="3" :state="validField('label')" invalid-feedback="Please provide a valid label.">
                            <b-form-input v-model="extension.label" :state="validField('label')" :readonly="!canEdit()"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Status" label-align-sm="right" label-cols-sm="3">
                            <b-form-select v-model="extension.status" :options="canEdit() ? statuses.filter(status => ['ENABLED','DISABLED'].includes(status.value)) : statuses" :disabled="!canEdit()"></b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body v-if="extension?.build">
                <b-card class="shadow rounded bg-white" no-body fluid>
                    <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'build')">
                        <b-img src="/img/icons/metrics.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                        <h5 class="text-secondary mb-0 mr-2">Build</h5>
                    </b-card-header>
                    <b-collapse id="build" visible>
                        <b-card-body>
                            <b-row>
                                <b-col xl="6" xxl="4">
                                    <b-form-group label="Status" label-align-sm="right" label-cols-sm="3">
                                        <b-form-select v-model="extension.build.status" :options="build_statuses" disabled></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <b-card-footer v-if="extension.build.updated_at" class="text-muted bg-light">
                            <small>Last updated at {{ extension.build.updated_at.toLocaleString() }}</small>
                        </b-card-footer>
                    </b-collapse>
                </b-card>
            </b-card-body>
            <b-card-body v-if="extension?.config">
                <b-card class="shadow rounded bg-white" no-body fluid>
                    <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'configuration')">
                        <b-img src="/img/icons/configuration.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                        <h5 class="text-secondary mb-0 mr-2">Configuration</h5>
                    </b-card-header>
                    <b-collapse id="configuration" visible>
                        <b-card-body>
                            <b-row>
                                <b-col xl="12">
                                    <b-form-group label="Code" label-align-sm="right" label-cols-sm="3" label-cols-xl="1" description="This is the code run by the extension.">
                                        <b-form-textarea v-model="extension.config.code" rows="10" readonly></b-form-textarea>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <b-card-body v-if="extension.config.rule">
                            <b-card class="shadow rounded bg-white" no-body fluid>
                                <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'rule')">
                                    <b-img src="/img/menu/events.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                                    <h5 class="text-secondary mb-0 mr-2">Rule</h5>
                                </b-card-header>
                                <b-collapse id="rule" visible>
                                    <b-card-body>
                                        <b-row>
                                            <b-col xl="6" xxl="4">
                                                <b-form-group label="Type" label-align-sm="right" label-cols-sm="3">
                                                    <b-form-tags v-model="extension.config.rule.type" disabled></b-form-tags>
                                                </b-form-group>
                                            </b-col>
                                            <b-col xl="6" xxl="4">
                                                <b-form-group label="Origin" label-align-sm="right" label-cols-sm="3">
                                                    <b-form-tags v-model="extension.config.rule.origin" disabled></b-form-tags>
                                                </b-form-group>
                                            </b-col>
                                            <b-col xl="6" xxl="4">
                                                <b-form-group label="Account" label-align-sm="right" label-cols-sm="3">
                                                    <b-form-tags v-model="extension.config.rule.account" disabled></b-form-tags>
                                                </b-form-group>
                                            </b-col>
                                            <b-col xl="6" xxl="4">
                                                <b-form-group label="Action" label-align-sm="right" label-cols-sm="3">
                                                    <b-form-tags v-model="extension.config.rule.action" disabled></b-form-tags>
                                                </b-form-group>
                                            </b-col>
                                            <b-col xl="6" xxl="4">
                                                <b-form-group label="Result" label-align-sm="right" label-cols-sm="3">
                                                    <b-form-tags v-model="extension.config.rule.result" disabled></b-form-tags>
                                                </b-form-group>
                                            </b-col>
                                            <b-col xl="6" xxl="4">
                                                <b-form-group label="Reason" label-align-sm="right" label-cols-sm="3">
                                                    <b-form-tags v-model="extension.config.rule.reason" disabled></b-form-tags>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </b-card-body>
            <b-card-body v-if="extension">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Created At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="extension.created_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Created By" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="extension.created_by" readonly></b-form-input>
                                <b-badge v-if="extension.created_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="`/accounts/${extension.created_by}`" variant="outline-primary" :disabled="extension.created_by === 'SYSTEM'">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="extension.updated_at">
                        <b-form-group label="Updated At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="extension.updated_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="extension.updated_by">
                        <b-form-group label="Updated By" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="extension.updated_by" readonly></b-form-input>
                                <b-badge v-if="extension.updated_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="`/accounts/${extension.updated_by}`" variant="outline-primary" :disabled="extension.updated_by === 'SYSTEM'">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="extension.expires_at">
                        <b-form-group label="Expires At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="extension.expires_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="success" v-on:click="saveExtension()" :disabled="!canEdit() || !validExtension()">Save</b-button>
                <b-button variant="outline-primary" class="ml-2" v-on:click="getExtension()">Refresh</b-button>
                <b-button variant="outline-danger" class="ml-auto" v-on:click="$emit('show', 'delete-extension', extension)" :disabled="!canEdit()">Delete</b-button>
            </b-card-body>
            <b-card-footer v-if="extension?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ extension.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const STATUSES = [
    { value: 'PENDING', text: 'Pending' },
    { value: 'ENABLED', text: 'Enabled' },
    { value: 'DISABLED', text: 'Disabled' },
    { value: 'LOCKED', text: 'Locked' }
];
const BUILD_STATUSES = [
    { value: 'QUEUED', text: 'Queued' },
    { value: 'STARTED', text: 'Started' },
    { value: 'SUCCEEDED', text: 'Succeeded' },
    { value: 'FAILED', text: 'Failed' }
];

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Control',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'show', 'save', 'next' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // LOADING
            loading: undefined,
            // EXTENSION
            extension: undefined,
            // STATUSES
            statuses: STATUSES,
            // STATUSES (BUILD)
            build_statuses: BUILD_STATUSES
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        if (!this.extension) {
            this.getExtension();
        }
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * EXTENSION
         */
        async getExtension() {
            this.loading = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query getExtension($id: ID!) {
                                getExtension(id: $id) {
                                    id
                                    label
                                    status
                                    config {
                                        code
                                        rule {
                                            type
                                            origin
                                            action
                                            result
                                            reason
                                            account
                                        }
                                    }
                                    build {
                                        status
                                        updated_at
                                    }
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                    expires_at
                                }
                            }
                        `,
                        variables: `{
                            "id": "${this.$route.params.id}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.extension = (await response.json()).data.getExtension;
                    // DECODE CODE
                    this.extension.config.code = atob(this.extension.config.code);
                    // SET DATES
                    this.extension.created_at = this.extension.created_at ? new Date(this.extension.created_at) : undefined;
                    this.extension.updated_at = this.extension.updated_at ? new Date(this.extension.updated_at) : undefined;
                    this.extension.expires_at = this.extension.expires_at ? new Date(this.extension.expires_at) : undefined;
                    // ADD REFRESH DATE
                    this.extension.refreshed_at = new Date();
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain extension.', 'Extension', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain extension.', 'Extension', 'danger');
            }
            this.loading = undefined;
        },

        async saveExtension() {
            this.loading = 'Saving';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation updateExtension($input: UpdateExtensionInput!) {
                                updateExtension(input: $input) {
                                    id
                                    label
                                    status
                                    config {
                                        code
                                        rule {
                                            type
                                            origin
                                            action
                                            result
                                            reason
                                            account
                                        }
                                    }
                                    build {
                                        status
                                        updated_at
                                    }
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                    expires_at
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "id": "${this.extension.id}",
                                "label": "${this.extension.label}",
                                "status": "${this.extension.status}"
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.extension = (await response.json()).data.updateExtension;
                    // DECODE CODE
                    this.extension.config.code = atob(this.extension.config.code);
                    // SET DATES
                    this.extension.created_at = this.extension.created_at ? new Date(this.extension.created_at) : undefined;
                    this.extension.updated_at = this.extension.updated_at ? new Date(this.extension.updated_at) : undefined;
                    this.extension.expires_at = this.extension.expires_at ? new Date(this.extension.expires_at) : undefined;
                    // ADD REFRESH DATE
                    this.extension.refreshed_at = new Date();
                    this.$emit('alert', 'Your extension has been updated', 'Extension', 'success', 5000);
                    this.$emit('load', 'extensions');
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to save extension.', 'Extension', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to save extension.', 'Extension', 'danger');
            }
            this.loading = undefined;
        },

        /**
         * VALIDATION
         */
        canEdit() {
            return ['ENABLED','DISABLED'].includes(this.extension?.status);
        },
        
        validExtension() {
            if (!this.validField('label')) return false;
            return true;
        },

        validField(field) {
            switch (field) {
                case 'label':
                    return !!this.extension.label;
                default:
                    return false;
            }
        }
    }
}
</script>