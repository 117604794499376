<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/attributes.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Attributes</h3>
                <b-form-input class="ml-auto" v-model="search" placeholder="Search" style="max-width: 20rem;"></b-form-input>
                <b-button class="ml-2" variant="primary" v-on:click="$emit('show', 'create-attribute', { config: {} })">Create</b-button>
            </b-card-header>
            <b-card-body v-if="$store.state.attributes">
                <b-table id="table" outlined hover :items="$store.state.attributes.items" :fields="fields" primary-key="id" sort-by="status" sort-desc :filter="search" per-page="10" :current-page="page" v-on:filtered="filteredItems => rows = filteredItems.length" v-on:row-clicked="item => $router.push(`/attributes/${item.id}`)" class="mb-0" show-empty empty-text="You have no attributes." empty-filtered-text="No attributes match your search. If more attributes are available you can load them by clicking 'Load More' below.">
                    <template #cell(status)="data">
                        <b-button :variant="variant(data.value)" size="sm" disabled>{{ statuses[data.value] }}</b-button>
                    </template>
                    <template #cell(actions)="data">
                        <b-button variant="outline-danger" size="sm" :disabled="['PENDING','LOCKED'].includes(data.item.status)" v-on:click="$emit('show', 'delete-attribute', data.item)">Delete</b-button>
                    </template>
                </b-table>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="outline-primary" v-on:click="$emit('load', 'attributes')">Refresh</b-button>
                <b-button variant="outline-primary" class="ml-2" v-on:click="page--" :disabled="page < 2">Previous</b-button>
                <b-button v-if="$store.state.attributes" variant="outline-primary" class="ml-2" v-on:click="page++" :disabled="!Math.max(( rows ?? $store.state.attributes.items.length) - page * 10, 0)">Next</b-button>
                <b-button v-if="$store.state.attributes?.nextToken" variant="outline-primary" v-on:click="$emit('next', 'attributes', $store.state.attributes.nextToken)" class="ml-2">Load More</b-button>
            </b-card-body>
            <b-card-footer v-if="$store.state.attributes?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ $store.state.attributes.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const FIELDS = [
    {
        key: 'label',
        label: 'Label',
        sortable: true
    },
    {
        key: 'subtype',
        label: 'Subtype',
        sortable: true,
        formatter: value => SUBTYPES[value]
    },
    {
        key: 'score',
        label: 'Score',
        sortable: true
    },
    {
        key: 'created_at',
        label: 'Created At',
        sortable: true,
        formatter: value => new Date(value).toLocaleString()
    },
    {
        key: 'status',
        label: 'Status',
        sortable: true
    },
    {
        key: 'actions',
        label: 'Actions',
        sortable: false
    }
];
const STATUSES = {
    'PENDING': 'Pending',
    'ENABLED': 'Enabled',
    'DISABLED': 'Disabled',
    'LOCKED': 'Locked'
};
const SUBTYPES = {
    'string': 'String',
    'number': 'Number',
    'boolean': 'Boolean',
    'url': 'URL',
    'string:oidc1:name': 'Name',
    'string:oidc1:given_name': 'Given Name',
    'string:oidc1:family_name': 'Family Name',
    'string:oidc1:middle_name': 'Middle Name',
    'string:oidc1:nickname': 'Nickname',
    'string:oidc1:preferred_username': 'Preferred Username',
    'url:oidc1:profile': 'Profile',
    'url:oidc1:picture': 'Picture',
    'url:oidc1:website': 'Website',
    'string:oidc1:email': 'Email',
    'string:oidc1:gender': 'Gender',
    'string:oidc1:birthdate': 'Birthdate',
    'string:oidc1:zoneinfo': 'Time Zone',
    'string:oidc1:locale': 'Locale',
    'string:oidc1:phone_number': 'Phone Number',
    'string:oidc1:address:formatted': 'Formatted Address',
    'string:oidc1:address:street_address': 'Street Address',
    'string:oidc1:address:locality': 'Locality',
    'string:oidc1:address:region': 'Region',
    'string:oidc1:address:postal_code': 'Postal Code',
    'string:oidc1:address:country': 'Country'
}

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Attributes',

    /**
     * EVENTS
     */
     emits: [ 'alert', 'login', 'load', 'next', 'show' ],

    /**
     * PROPERTIES
     */
    props: {
        loading: String,
        filter: Function,
        variant: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // FIELDS
            fields: FIELDS,
            // STATUSES
            statuses: STATUSES,
            // PAGE,
            page: 1,
            // ROWS
            rows: undefined,
            // SEARCH
            search: undefined
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        if (!this.$store.state.attributes) {
            this.$emit('load', 'attributes');
        }
    }
}
</script>