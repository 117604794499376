<template>
    <b-overlay :show="loading">

        <!--
        LOADING
        -->
        <template #overlay>
            <div class="text-center">
                <b-spinner variant="primary"></b-spinner>
                <p><small class="text-primary">{{ loading }}</small></p>
            </div>
        </template>

        <!--
        PROFILE
        -->
        <b-card class="shadow rounded bg-white" no-body fluid>
            <b-card-header class="bg-light d-flex">
                <b-img src="/img/menu/controls.svg" height="35px" width="35px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                <h3 class="text-secondary mb-0 mr-2">Control</h3>
            </b-card-header>
            <b-card-body v-if="control">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="ID" label-align-sm="right" label-cols-sm="3">
                            <b-form-input v-model="control.id" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Label" label-align-sm="right" label-cols-sm="3" :state="validField('label')" invalid-feedback="Please provide a valid label.">
                            <b-form-input v-model="control.label" :state="validField('label')" :readonly="!canEdit()"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Subtype" label-align-sm="right" label-cols-sm="3">
                            <b-form-select v-model="control.subtype" :options="subtypes" disabled></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Status" label-align-sm="right" label-cols-sm="3">
                            <b-form-select v-model="control.status" :options="canEdit() ? statuses.filter(status => ['ENABLED','DISABLED'].includes(status.value)) : statuses" :disabled="!canEdit()"></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Score" label-align-sm="right" label-cols-sm="3" description="This is the minimum security score a user needs to succesfully pass this control." :state="validField('score')" invalid-feedback="Please provide a valid score. The minimum is 0.">
                            <b-form-input v-model="control.score" type="number" min="0" :state="validField('score')" :readonly="!canEdit()"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label-align-sm="right" label-cols-sm="3" :description="control.subtype === 'scope' ? 'This is the name of the OAuth 2.0 scope that will be included in an access token (used for API access).' : 'This can either be an URL to the legal text or the legal text itself. Note that our Login UI only supports URLs.'" :state="validField('value')" invalid-feedback="Please provide a valid value.">
                            <template #label>
                                Value<b-badge v-if="control.subtype === 'scope'" class="ml-2" variant="oauth2">OAuth 2.0</b-badge>
                            </template>
                            <b-form-input v-model="control.value" :state="validField('value')" :readonly="!canEdit()"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body v-if="control?.config">
                <b-card class="shadow rounded bg-white" no-body fluid>
                    <b-card-header class="bg-light d-flex" v-on:click="$root.$emit('bv::toggle::collapse', 'configuration')">
                        <b-img src="/img/icons/configuration.svg" height="25px" width="25px" class="mr-2" :style="`filter: ${filter('secondary')}`"></b-img>
                        <h5 class="text-secondary mb-0 mr-2">Configuration</h5>
                    </b-card-header>
                    <b-collapse id="configuration" visible>
                        <b-card-body>
                            <b-row>
                                <b-col xl="6" xxl="4">
                                    <b-form-group label="Consent Required" label-align-sm="right" label-cols-sm="3" description="This indicates whether accounts must provide consent to pass this control. Note that consent will not be required regardless of this setting if (a) an account grants a scope to an internal application, (b) an internal account must pass a legal control or (c) an application requests a scope to itself.">
                                        <b-form-checkbox v-model="control.config.consent_required" switch :disabled="!canEdit() || control.subtype === 'legal'"></b-form-checkbox>
                                    </b-form-group>
                                </b-col>
                                <b-col xl="6" xxl="4">
                                    <b-form-group label="Permission Required" label-align-sm="right" label-cols-sm="3" description="This indicates whether accounts must have permission to pass this control.">
                                        <b-form-checkbox v-model="control.config.permission_required" switch :disabled="!canEdit() || control.subtype === 'legal'"></b-form-checkbox>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </b-card-body>
            <b-card-body v-if="control">
                <b-row>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Created At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="control.created_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4">
                        <b-form-group label="Created By" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="control.created_by" readonly></b-form-input>
                                <b-badge v-if="control.created_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="`/accounts/${control.created_by}`" variant="outline-primary" :disabled="control.created_by === 'SYSTEM'">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="control.updated_at">
                        <b-form-group label="Updated At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="control.updated_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="control.updated_by">
                        <b-form-group label="Updated By" label-align-sm="right" label-cols-sm="3">
                            <b-input-group>
                                <b-form-input v-model="control.updated_by" readonly></b-form-input>
                                <b-badge v-if="control.updated_by === $store.state.account_id" style="position: absolute; right: 75px; bottom: 10px" variant="primary">YOU</b-badge>
                                <b-input-group-append>
                                    <b-button :to="`/accounts/${control.updated_by}`" variant="outline-primary" :disabled="control.updated_by === 'SYSTEM'">Open</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col xl="6" xxl="4" v-if="control.expires_at">
                        <b-form-group label="Expires At" label-align-sm="right" label-cols-sm="3">
                            <b-form-input :value="control.expires_at.toLocaleString()" readonly></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-body class="d-flex">
                <b-button variant="success" v-on:click="saveControl()" :disabled="!canEdit() || !validControl()">Save</b-button>
                <b-button variant="outline-primary" class="ml-2" v-on:click="getControl()">Refresh</b-button>
                <b-button variant="outline-danger" class="ml-auto" v-on:click="$emit('show', 'delete-control', control)" :disabled="!canEdit()">Delete</b-button>
            </b-card-body>
            <b-card-footer v-if="control?.refreshed_at" class="text-muted bg-light">
                <small>Last refreshed at {{ control.refreshed_at.toLocaleString() }}</small>
            </b-card-footer>
        </b-card>

    </b-overlay>
</template>

<!--
SCRIPT
-->
<script>

/**
 * CONFIGURATION
 */
const STATUSES = [
    { value: 'ENABLED', text: 'Enabled' },
    { value: 'DISABLED', text: 'Disabled' },
    { value: 'LOCKED', text: 'Locked' }
];
const SUBTYPES = [
    { value: 'legal', text: 'Legal' },
    { value: 'scope', text: 'Scope' }
];

/**
 * EXPORTS
 */
 export default {
    
    /**
     * NAME
     */
    name: 'Control',

    /**
     * EVENTS
     */
    emits: [ 'alert', 'login', 'load', 'show', 'save', 'next' ],

    /**
     * PROPERTIES
     */
    props: {
        filter: Function
    },

    /**
     * DATA
     */
    data() {
        return {
            // LOADING
            loading: undefined,
            // CONTROL
            control: undefined,
            // STATUSES
            statuses: STATUSES,
            // SUBTYPES
            subtypes: SUBTYPES
        }
    },

    /**
     * BOOTSTRAP VUE 3 SUPPORT
     */
    compatConfig: { MODE: 2 },

    /**
     * CONSTRUCTOR
     */
    async created() {
        if (!this.control) {
            this.getControl();
        }
    },

    /**
     * METHODS
     */
    methods: {

        /**
         * CONTROL
         */
        async getControl() {
            this.loading = 'Loading';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            query getControl($id: ID!) {
                                getControl(id: $id) {
                                    id
                                    label
                                    value
                                    score
                                    status
                                    subtype
                                    config {
                                        consent_required
                                        permission_required
                                    }
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                }
                            }
                        `,
                        variables: `{
                            "id": "${this.$route.params.id}"
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.control = (await response.json()).data.getControl;
                    // SET DATES
                    this.control.created_at = this.control.created_at ? new Date(this.control.created_at) : undefined;
                    this.control.updated_at = this.control.updated_at ? new Date(this.control.updated_at) : undefined;
                    this.control.expires_at = this.control.expires_at ? new Date(this.control.expires_at) : undefined;
                    // ADD REFRESH DATE
                    this.control.refreshed_at = new Date();
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to obtain control.', 'Control', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to obtain control.', 'Control', 'danger');
            }
            this.loading = undefined;
        },

        async saveControl() {
            this.loading = 'Saving';
            try {

                // GET ACCOUNT
                const response = await fetch(`https://${this.$store.state.tenant_id}.api${this.$store.state.domain}/graphql`, {
                    method: 'POST',
                    body: JSON.stringify({
                        query: `
                            mutation updateControl($input: UpdateControlInput!) {
                                updateControl(input: $input) {
                                    id
                                    label
                                    value
                                    score
                                    status
                                    subtype
                                    config {
                                        consent_required
                                        permission_required
                                    }
                                    created_at
                                    created_by
                                    updated_at
                                    updated_by
                                }
                            }
                        `,
                        variables: `{
                            "input": {
                                "id": "${this.control.id}",
                                "subtype": "${this.control.subtype}",
                                "value": "${this.control.value}",
                                "label": "${this.control.label}",
                                "status": "${this.control.status}",
                                "score": ${this.control.score},
                                "config": {
                                    "consent_required": ${this.control.config.consent_required},
                                    "permission_required": ${this.control.config.permission_required}
                                }
                            }
                        }`
                    }),
                    headers: {
                        Authorization: `Bearer ${this.$store.state.session}`
                    }
                });

                // VERIFY RESPONSE
                if (response.ok) {
                    this.control = (await response.json()).data.updateControl;
                    // SET DATES
                    this.control.created_at = this.control.created_at ? new Date(this.control.created_at) : undefined;
                    this.control.updated_at = this.control.updated_at ? new Date(this.control.updated_at) : undefined;
                    this.control.expires_at = this.control.expires_at ? new Date(this.control.expires_at) : undefined;
                    // ADD REFRESH DATE
                    this.control.refreshed_at = new Date();
                    this.$emit('alert', 'Your control has been updated', 'Control', 'success', 5000);
                    this.$emit('load', 'controls');
                // EXPIRED SESSION
                } else if (response.status === 403 || response.status === 401) {
                    this.$emit('alert', 'Your session has expired.', 'Authentication', 'warning', 5000);
                    this.$emit('login');
                } else {
                    this.$emit('alert', 'Failed to save control.', 'Control', 'danger');
                }

            } catch (error) {
                this.$emit('alert', 'Failed to save control.', 'Control', 'danger');
            }
            this.loading = undefined;
        },

        /**
         * VALIDATION
         */
        canEdit() {
            return ['ENABLED','DISABLED'].includes(this.control?.status);
        },
        
        validControl() {
            if (!this.validField('label')) return false;
            if (!this.validField('score')) return false;
            if (!this.validField('value')) return false;
            return true;
        },

        validField(field) {
            switch (field) {
                case 'label':
                    return !!this.control.label;
                case 'score':
                    return this.control.score >= 0;
                case 'value':
                    return !!this.control.label;
                default:
                    return false;
            }
        }
    }
}
</script>